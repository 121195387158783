import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import LogoBVDesktop from "../../assets/logoBV green 2 career.png";
import LogoBVMobile from "../../assets/logoBV green 2 career.png";

function ITapplicatiebe() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">
          IT Applicatiebeheerder
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">Over Ons</h2>
        <p className="text-gray-700 mb-4">
          Bij IT Solutions Worldwide zijn we trots op onze rol als vertrouwde
          partner voor bedrijven die streven naar strategisch advies en
          innovatieve oplossingen. Met diepgaande ervaring in diverse sectoren
          levert ons team van ervaren consultants ongeëvenaarde expertise en een
          klantgerichte benadering bij elk project. We zijn gepassioneerd over
          het helpen van onze klanten om hun doelstellingen te bereiken en hun
          concurrentiepositie te versterken in een voortdurend veranderende
          markt. Sluit je aan bij ons en werk mee aan het vormgeven van
          toekomstgerichte bedrijfsoplossingen.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">Over de klant</h2>
        <p className="text-gray-700 mb-4">
          Voor een klant zijn we op zoek naar een IT applicatiebeheerder. De
          klant is gevestigd in Ypenburg en heeft meer dan 150 collega’s. Ze
          zijn gedreven om hightech mechatronische systemen te ontwikkelen én
          seriematig te produceren. Dit doen ze voor de halfgeleiderindustrie,
          medische sector en laboratoriummarkt.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Functieomschrijving
        </h2>
        <p className="text-gray-700 mb-4">
          Heb je een passie voor het optimaliseren van ERP systemen en het
          vertalen van bedrijfsbehoeften naar effectieve IT-oplossingen? Dan ben
          jij misschien de persoon die we zoeken! Als IT applicatiebeheerder ben
          je verantwoordelijk voor het waarborgen van de functionaliteit van
          diverse systemen met een primaire focus op ons ERP-systeem en
          gerelateerde applicaties. Je speelt een cruciale rol in het verbeteren
          van operationele efficiëntie en effectiviteit door technische en
          functionele optimalisaties.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Verantwoordelijkheden
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Beheren, monitoren en onderhouden van ERP- en gerelateerde
            randapplicaties op zowel technisch als functioneel niveau, naast
            algemene zakelijke applicaties.
          </li>
          <li>
            Identificeren van bedrijfsbehoeften en deze vertalen naar technische
            en functionele oplossingen.
          </li>
          <li>
            Uitvoeren van grondige bedrijfsanalyses voor verbeteringen en
            oplossingen, met name gericht op ERP- en gerelateerde systemen.
          </li>
          <li>
            Coördineren van applicatie-updates en implementaties en zorgen voor
            de functionaliteit, met bijzondere aandacht voor ERP-gerelateerde
            applicaties.
          </li>
          <li>
            Gebruikers ondersteunen bij technische problemen en trainingen
            geven.
          </li>
          <li>
            Implementeren van technische wijzigingen aan applicaties en
            monitoren van de prestaties.
          </li>
          <li>
            Evalueren van wijzigingsverzoeken, gebruikersbeheer, rollen en
            rechten.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Vereisten</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>Een afgeronde relevante HBO-opleiding.</li>
          <li>Minimaal 5 jaar ervaring in een vergelijkbare rol.</li>
          <li>
            Beheersing van zowel Nederlands als Engels, zowel mondeling als
            schriftelijk.
          </li>
          <li>
            Diepgaande kennis van IT-systemen en architectuur, applicatiebeheer,
            met name ERP-systemen.
          </li>
          <li>Kennis van ERP, SQL, SAP Business Objects en Power BI.</li>
          <li>
            Ervaring met SQL en de mogelijkheid om queries te schrijven en te
            begrijpen.
          </li>
          <li>
            Vaardigheid in SAP Business Objects en/of Power BI voor data-analyse
            en visualisatie.
          </li>
          <li>
            Sterke analytische vaardigheden voor zowel bedrijfsanalyses als
            technische vraagstukken.
          </li>
          <li>
            Ervaring in het coördineren van applicatie-updates en
            implementaties.
          </li>
          <li>
            Uitstekende communicatieve vaardigheden en stakeholdermanagement.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Aanbod</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Een uitdagende rol met ruimte voor initiatief en ontwikkeling.
          </li>
          <li>
            Samenwerking in een dynamisch team binnen een groeiende organisatie.
          </li>
          <li>Hybride werken.</li>
          <li>Laptop en telefoon.</li>
          <li>Omzetrendementsregeling per kwartaal.</li>
          <li>Trainingen binnen en buiten onze organisatie.</li>
          <li>Marktconform salaris.</li>
          <li>CAO Metalektro, inclusief 27 vakantiedagen en 13 ADV-dagen.</li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">
          Geïnteresseerd?
        </h2>
        <p className="text-gray-700 mb-4 text-center">
          Stuur je cv en motivatiebrief naar info@itsolutionsworldwide.com of
          reageer via onze website vacature.
        </p>
      </div>
    </div>
  );
}

export default ITapplicatiebe;
