import React from "react";
import { Link } from "react-router-dom";
import LogoBVDesktop from "../../assets/logoBV green 2 career.png";
import LogoBVMobile from "../../assets/logoBV green 2 career.png";
function Technische() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Technische Koper
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">Over Ons</h2>
        <p className="text-gray-700 mb-4">
          Bij IT Solutions Worldwide zijn we trots op onze rol als vertrouwde
          partner voor bedrijven die streven naar strategisch advies en
          innovatieve oplossingen. Met diepgaande ervaring in diverse sectoren
          levert ons team van ervaren consultants ongeëvenaarde expertise en een
          klantgerichte benadering bij elk project. We zijn gepassioneerd over
          het helpen van onze klanten om hun doelstellingen te bereiken en hun
          concurrentiepositie te versterken in een voortdurend veranderende
          markt. Sluit je aan bij ons en werk mee aan het vormgeven van
          toekomstgerichte bedrijfsoplossingen.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">Over de klant</h2>
        <p className="text-gray-700 mb-4">
          Voor een klant zijn we op zoek naar een technische inkoper. De klant
          is gevestigd in Ypenburg en heeft meer dan 150 collega’s. Ze zijn
          gedreven om hightech mechatronische systemen te ontwikkelen én
          seriematig te produceren. Dit doen ze voor de halfgeleiderindustrie,
          medische sector en laboratoriummarkt.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Functieomschrijving
        </h2>
        <p className="text-gray-700 mb-4">
          Als Technisch Inkoper ben je betrokken bij Nieuwe Productintroducties
          (NPI's) en ben je verantwoordelijk voor het ontwikkelen van
          inkoopspecificaties en het afhandelen van taken zoals
          leveranciersselectie, offerteprocessen, onderhandelingen,
          contractering en het plaatsen van inkooporders. Je speelt een cruciale
          rol in het succes van onze projecten door ervoor te zorgen dat de
          juiste materialen op tijd en binnen het budget beschikbaar zijn. Je
          hebt een ‘can-do’ houding en de flexibiliteit om te schakelen tussen
          strategische en operationele taken. Het uiteindelijke doel is een
          soepele en winstgevende serieproductie te waarborgen.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Verantwoordelijkheden
        </h2>
        <ul className="text-gray-700 list-disc mb-4 pl-5">
          <li>
            Onderhandelen met leveranciers over prijzen, voorwaarden en
            leveringsschema's.
          </li>
          <li>
            Nauw samenwerken met projectmanagers om de inkoopstrategie af te
            stemmen op de projectdoelen.
          </li>
          <li>
            Administratieve zaken documenteren, zoals notulen van vergaderingen,
            offerteaanvragen en inkooporders.
          </li>
          <li>
            Proactief zoeken naar mogelijkheden voor kostenbesparingen en
            procesverbeteringen.
          </li>
          <li>
            Planningen en opleveringen bewaken, en interne en externe
            belanghebbenden op de hoogte houden.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Vereisten</h2>
        <ul className="text-gray-700 list-disc mb-4 pl-5">
          <li>Een bachelor- of masterdiploma in een relevant vakgebied.</li>
          <li>Ervaring in de technische sector is essentieel.</li>
          <li>Ervaring met onderhandelingen.</li>
          <li>Vaardigheid met Excel en ERP-systemen.</li>
          <li>Sterke communicatievaardigheden in het Nederlands en Engels.</li>
          <li>Minimaal 2 jaar ervaring in technische en tactische inkoop.</li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Aanbod</h2>
        <ul className="text-gray-700 list-disc mb-4 pl-5">
          <li>Een uitdagende functie in een hightech omgeving.</li>
          <li>Hybride werken.</li>
          <li>Laptop en telefoon.</li>
          <li>Winstdelingsregeling per kwartaal.</li>
          <li>Opleidingsmogelijkheden binnen en buiten onze organisatie.</li>
          <li>Marktconform salaris.</li>
          <li>
            CAO Metalektro met onder andere 27 vakantiedagen en 13 ADV dagen.
          </li>
        </ul>
        <p className="text-gray-700 mb-4">
          Geïnteresseerd? Stuur je cv en motivatiebrief naar
          info@itsolutionsworldwide.com of reageer via onze website vacature.
        </p>
      </div>
    </div>
  );
}

export default Technische;
